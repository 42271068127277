import type {
  CompanyTypeRow,
  ProductTypeRow,
  SurveyResponseTypeRow,
} from '@openesg/supabase/models'

export const fetchSurveyResponse = (
  productId?: ProductTypeRow['id'],
  companyId?: CompanyTypeRow['id']
) => {
  return $fetch(`/api/surveys/responses/`, {
    method: 'GET',
    params: {
      ...(productId ? { productId } : {}),
      ...(companyId ? { companyId } : {}),
    },
  })
}

export const generateSurveyResponse = (
  productId?: ProductTypeRow['id'],
  companyId?: CompanyTypeRow['id']
) => {
  return $fetch(`/api/surveys/responses/`, {
    method: 'POST',
    params: {
      ...(productId ? { productId } : {}),
      ...(companyId ? { companyId } : {}),
    },
  })
}

export const submitScoreVerification = (id: SurveyResponseTypeRow['id']) => {
  return $fetch(`/api/surveys/responses/${id}/pending`, { method: 'PUT' })
}
