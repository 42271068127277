import type {
  CompanyTypeRow,
  ProductTypeRow,
  SurveyResponseTypeRow,
} from '@openesg/supabase/models'
import {
  fetchSurveyResponse,
  generateSurveyResponse,
  submitScoreVerification,
} from '~~/services/SurveyResponseServices'

import { useProfileStore } from './profile.store'

export const useSurveyResponseStore = defineStore('surveyResponse', () => {
  const surveyResponse = ref<SurveyResponseTypeRow | undefined>(undefined)
  const { isProUser } = storeToRefs(useProfileStore())
  const PUBLISH_THRESHOLD_SCORE = 40

  const getSurveyResponse = async (
    productId?: ProductTypeRow['id'],
    companyId?: CompanyTypeRow['id']
  ) => {
    updateResponse(undefined)
    const { data } = await fetchSurveyResponse(productId, companyId)
    updateResponse(data as SurveyResponseTypeRow)
  }

  const createSurveyResponse = async (
    productId?: ProductTypeRow['id'],
    companyId?: CompanyTypeRow['id']
  ) => {
    const { data } = await generateSurveyResponse(productId, companyId)
    updateResponse(data as SurveyResponseTypeRow)
  }

  const updateResponse = (data: SurveyResponseTypeRow | undefined) => {
    // console.log('updating data', data)
    surveyResponse.value = data ? data : undefined
  }

  const submitVerification = async () => {
    if (surveyResponse.value?.id) {
      const { data } = await submitScoreVerification(surveyResponse.value.id)
      if (data) updateResponse(data as SurveyResponseTypeRow)
    }
  }

  const hasSurveyScore = computed(
    () => !!(surveyResponse.value && surveyResponse.value?.score !== null)
  )

  const hasVerificationPending = computed(
    () => isProUser.value && surveyResponse.value?.status === 'pending'
  )

  const hasMetPublishThreshold = computed(
    () =>
      surveyResponse.value?.score &&
      surveyResponse.value.score >= PUBLISH_THRESHOLD_SCORE
  )

  return {
    surveyResponse,
    PUBLISH_THRESHOLD_SCORE,
    hasSurveyScore,
    hasVerificationPending,
    updateResponse,
    getSurveyResponse,
    createSurveyResponse,
    submitVerification,
    hasMetPublishThreshold,
  }
})
